import React from "react"
import { graphql } from "gatsby"

import Layout from 'components/layout'


const  BlogTemplate = ({ data, location }) => {

  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout location={location}>
      <article className="blog-post" itemScope itemType="http://schema.org/Article">
        <header>
          <h1 itemProp="headline">{frontmatter.title}</h1>
          <p>{frontmatter.date}</p>
        </header>
        <section dangerouslySetInnerHTML={{ __html: html }} itemProp="articleBody" />
        <hr />
        <footer>
        </footer>
      </article>
    </Layout>
  )
}

export default BlogTemplate 

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`